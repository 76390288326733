import React, { useState, useEffect } from "react";
import StripCaricaFoto from "./components/StripCaricaFoto";
import PosizioneRegalo from "./components/PosizioneRegalo";
import CategoriaRegalo from "./components/CategoriaRegalo";
import StatusRegalo from "./components/StatusRegalo";
import DisponibilitaRegalo from "./components/DisponibilitaRegalo";
import NomeDescrizioneRegalo from "./components/NomeDescrizioneRegalo";
import LoadingBtn from "../../Components/Atoms/Bottoni/LoadingBtn";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";
import useEditingGiftStore from "../../stores/editingGiftStore";
import useNavbarStore from "../../stores/navbarStore";
import { toast } from "sonner";
import Indietro from "../../Components/Atoms/Bottoni/Indietro";
import AdaptiveDrawer from "../../Components/Atoms/Drawers/AdaptiveDrawer";
import { useNavigate } from "react-router-dom";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const PaginaRegala = () => {
    const [giftPublished, setGiftPublished] = useState(false);
    const pubblicaRegalo = useEditingGiftStore((state) => state.pubblicaRegalo);
    const resetEditingGift = useEditingGiftStore((state) => state.reset);
    const canProceed = useEditingGiftStore(
        (state) => state.checkFieldsValidity
    );

    const setNavbar = useNavbarStore((state) => state.setNavbarVisible);
    const setFootbar = useNavbarStore((state) => state.setFootbarVisible);

    useEffect(() => {
        setNavbar(false);
        setFootbar(false);
        resetEditingGift();
        window.addEventListener("beforeunload", resetEditingGift);
        return () => {
            window.removeEventListener("beforeunload", resetEditingGift);
        };
    }, [window.location.pathname]);

    const handlePubblicaRegalo = async () => {
        if (!canProceed()) {
            toast.error("Compila prima tutti i campi");
            throw new Error("Compila prima tutti i campi");
        }
        try {
            await pubblicaRegalo();
            toast.success("Regalo pubblicato con successo");
            setGiftPublished(true);
        } catch (error) {
            toast.error(`Errore nella pubblicazione del regalo: ${error}`);
            throw new Error(`Errore nella pubblicazione del regalo: ${error}`);
        }
    };

    const navigate = useNavigate();
    const handleConfirmPostDrawer = () => {
        // TODO: reindirizzare alla pagina prodotto aperto
        navigate("/trattative");
    };

    return (
        <>
            <div className="flex items-center justify-center py-5 border-b-2">
                <span className="absolute left-0">
                    <Indietro />
                </span>
                <h1 className="text-3xl font-bold">Pubblica</h1>
                <p></p>
            </div>
            <div className="px-5 md:px-0 md:w-2/5 mx-auto text-verdeScuro pb-16">
                <div className="border-b-2">
                    <StripCaricaFoto />
                </div>
                <div className="border-b-2">
                    <PosizioneRegalo />
                </div>
                <div className="border-b-2">
                    <CategoriaRegalo />
                </div>
                <div className="border-b-2">
                    <DisponibilitaRegalo />
                </div>
                <div className="border-b-2">
                    <StatusRegalo />
                </div>
                <div className="border-b-0">
                    <NomeDescrizioneRegalo />
                </div>
                <div className="font-light px-2 pt-5 text-verdeScuro">
                    <p>Prima di pubblicare controlla di aver rispettato le <a className="text-verde underline cursor-pointer" onClick={() => navigate("/regolamento")}>linee guida</a> di ToGiftIt.</p>
                </div>
                <div className="mt-5">
                    <LoadingBtn fullWidth notime onCall={handlePubblicaRegalo}>
                        Pubblica
                    </LoadingBtn>
                </div>
            </div>
            <AdaptiveDrawer
                isOpen={giftPublished}
                setOpen={() => handleConfirmPostDrawer()}
            >
                <div className="w-full p-4 flex flex-col gap-5">
                    <div className="w-full px-4 flex flex-col gap-5">
                        <p className="text-center text-3xl font-bold">
                            Regalo pubblicato!
                        </p>
                        <div className="w-full flex justify-center">
                            {" "}
                            <div className="w-44 h-32 object-cover  relative">
                                <img
                                    src={require("../../assets/Illustrazioni/regalo pubblicato.gif")}
                                    alt="regalo pubblicato"
                                    className="absolute bottom-0"
                                ></img>
                            </div>
                        </div>
                        <div className="">
                            <p className="text-center">
                                Grazie per la tua{" "}
                                <span className="font-bold">Donazione :)</span>
                            </p>
                            <p className="text-center">
                                Stai contribuendo a rendere il mondo un posto
                                migliore, grazie per la tua generosità!
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center w-full mt-10">
                        <PrimaryButton
                            fullWidth
                            onClick={handleConfirmPostDrawer}
                        >
                            Continua
                        </PrimaryButton>
                    </div>
                </div>
            </AdaptiveDrawer>
        </>
    );
};

export default PaginaRegala;
