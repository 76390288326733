import React, { useEffect, useRef, useState } from "react";
import Bottone from "../../Components/Atoms/Bottoni/PrimaryButton";
import TextInputField from "../../Components/Atoms/Inputs/TextInputField";
import { FcGoogle } from "react-icons/fc";

import {
    GoogleAuthProvider,
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
} from "firebase/auth";
import { auth } from "../../firebase";
import Iconfrecciagiu from "../../assets/Icons/Iconfrecciagiu";
import texture_white from "../../assets/Textures/Texture Square grigio 2.svg";
import SecondaryButton from "../../Components/Atoms/Bottoni/SecondaryButton";
import { toast } from "sonner";
import bagliore_sfondo from "../../assets/Sfondi/bagliore_sfondo.svg";
import UserAPI from "../../utils/API/UserAPI";
import { Link } from "react-router-dom";
import useFilterStore from "../../stores/filterStore";
import PrimaryButton from "../../Components/Atoms/Bottoni/PrimaryButton";

const Login = () => {
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const buttonRef = useRef(null);
    const [sending, setSending] = useState(false);
    const { resetFilters, setCoordinate } = useFilterStore();
    const buttonInnerClassName = "flex items-center justify-between w-full px-0";

    const signInWithMail = () => {
        //e.preventDefault();
        setError("");

        if (sending) return; // Evita chiamate multiple
        setSending(true);

        signInWithEmailAndPassword(auth, mail, password)
            .then(async (userCredential) => {
                console.log("User logged in:", userCredential.user);
                setSending(false);
                window.localStorage.clear();
                resetFilters();
                const user = await UserAPI.getById(userCredential.user.uid);

                setCoordinate({
                    lat: user?.location ? user.location.lat : null,
                    lng: user?.location ? user.location.lng : null,
                    citta: user?.location ? user.location.city : null,
                    range: user?.location ? 100 : null,
                });

                window.location.href = "/"; // Gestisci il successo del login
            })
            .catch((error) => {
                console.error(
                    "Error signing in with email and password:",
                    error
                );
                if (
                    error.code === "auth/invalid-email" ||
                    error.code === "auth/user-not-found"
                ) {
                    setError("invalid-email");
                    toast.error("Email o password errati");
                } else if (
                    error.code === "auth/wrong-password" ||
                    error.code === "auth/invalid-credential"
                ) {
                    setError("wrong-password");
                    toast.error("Email o password errati");
                }
                setSending(false);
            });
    };

    const signInWithApple = async () => {
        const provider = new OAuthProvider("apple.com");
        if (window.ReactNativeWebView) {
            signInWithRedirect(auth, provider); // Avvia il flusso di redirect
        } else {
            const result = await signInWithPopup(auth, provider);

            const uid = result.user.uid;
            try {
                const user = await UserAPI.getById(uid);
                if (user.uid) {
                    window.localStorage.clear();
                    console.log("User logged in:", user);

                    window.history.back();
                    return;
                }
            } catch (error) {
                console.log("User logged in:", error);
                window.location.href = "/register";
            }
        }
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        if (window.ReactNativeWebView) {
            signInWithRedirect(auth, provider); // Avvia il flusso di redirect
        } else {
            const result = await signInWithPopup(auth, provider);

            const uid = result.user.uid;
            try {
                const user = await UserAPI.getById(uid);
                if (user.uid) {
                    window.localStorage.clear();
                    console.log("User logged in:", user);

                    window.history.back();
                    return;
                }
            } catch (error) {
                console.log("User logged in:", error);
                window.location.href = "/register";
            }
            //window.history.back();
        }
    };

    // Gestisci il risultato del redirect

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter" && !sending) {
                signInWithMail(e);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        // Cleanup del listener quando il componente viene smontato
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [mail, password, sending]); // Aggiunta di dipendenze per riflettere gli stati aggiornati

    return (
        <div
            style={{
                backgroundImage: `url('${texture_white}')`,
                backgroundRepeat: "repeat",
                backgroundSize: "cover",
            }}
            className="overflow-x-hidden min-h-[85vh]"
        >
            <div
                style={{
                    backgroundImage: `url('${bagliore_sfondo}'`,
                    backgroundSize: "110%",
                }}
                className="bg-center min-h-[85vh] bg-no-repeat"
            >
                <button
                    className={
                        "relative flex items-center top-0 pl-3 overflow-x-hidden pt-3 left-0 w-auto z-10 text-3xl text-black"
                    }
                    onClick={() => (window.location.href = "/")}
                >
                    <div className="w-6">
                        <Iconfrecciagiu />
                    </div>
                </button>
                <div className="flex gap-10 justify-center w-[100vw]">
                    <div className="flex flex-col md:flex-[0.4] flex-1  px-8 py-8">
                        <div className="flex flex-col gap-20 flex-1 justify-around">
                            <div className="flex flex-col items-center text-4xl text-black ">
                                <p className="font-semibold">Accedi</p>
                                <p className="text-center text-base mt-2">
                                    Non hai un account?{" "}
                                    <Link
                                        to="/register"
                                        className="font-semibold underline"
                                    >
                                        Registrati
                                    </Link>
                                </p>
                            </div>
                        </div>

                        <div className="flex bg-center bg-no-repeat bg- flex-col flex-1 justify-around text-black">
                            <div className="flex flex-col space-y-1">
                                <h6 className="font-semibold text-lg">Email</h6>
                                <TextInputField
                                    value={mail}
                                    setValue={setMail}
                                    placeholder={"Email"}
                                    inputStyle={`text-base text-verdeScuro w-full  ${
                                        error
                                            ? "border-rosso focus:border-2 focus:border-rosso "
                                            : "border-verdeScuro focus:border-verdeScuro"
                                    } focus:ring-0 rounded-md`}
                                />
                                {/*error  && (
                                <p className="text-sm text-rosso">
                                    Email non valida
                                </p>
                            )*/}
                            </div>
                            <div className="flex flex-col mt-5 space-y-1">
                                <h6 className="font-semibold text-lg">
                                    Password
                                </h6>
                                <TextInputField
                                    value={password}
                                    setValue={setPassword}
                                    password
                                    placeholder={"Password"}
                                    inputStyle={`text-base text-verdeScuro w-full ${
                                        error
                                            ? "border-rosso focus:border-2 focus:border-rosso "
                                            : "border-verdeScuro focus:border-verdeScuro"
                                    } focus:ring-0 rounded-md`}
                                />
                                {/*error && (
                                <p className="text-sm text-rosso">
                                    Password errata
                                </p>
                            )*/}
                                <p className="text-sm  pt-2 pb-8">
                                    {" "}
                                    <a
                                        className="underline"
                                        href="/resetpassword"
                                    >
                                        Non ti ricordi la password?
                                    </a>
                                </p>
                            </div>
                            <Bottone
                                ref={buttonRef}
                                onClick={(e) => signInWithMail(e)}
                                height={12}
                            >
                                Accedi
                            </Bottone>
                        </div>
                        <div className="flex my-10 justify-center items-center text-2xl  text-black space-x-5">
                            <div className="w-2/12 border-b-2 border-white" />
                            <h5 className="">Oppure</h5>
                            <div className="w-2/12 border-b-2 border-white" />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <SecondaryButton
                                onClick={() => signInWithGoogle()}
                                height={12}
                            >
                                <div className={buttonInnerClassName}>
                                    <FcGoogle className=" text-2xl" />
                                    <p>Continua con Google</p>
                            <p></p>
                                </div>
                            </SecondaryButton>
                            <PrimaryButton
                            bgColor="bg-black"
                                onClick={() => signInWithApple()}
                                height={12}
                            >
                                <div className={buttonInnerClassName}>
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                                        alt="Apple"
                                        className="w-6 invert"
                                    />
                                    <p>Continua con Apple</p>
                                    <p></p>
                                </div>
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
